<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll v-if="detail">
        <inputItem title="车牌号码" type="select" :options="carOption" :initialContent="getCarName(detail.carid)" :disabled="disabled" name="carid" :contentChanged="contentChanged"></inputItem>
        <inputItem title="申请人员" v-model="detail.applyusername" :disabled="true"></inputItem>
        <inputItem title="协同人员" v-model="detail.team_user" :disabled="disabled"></inputItem>
        <inputItem title="目的地" v-model="detail.destination" :disabled="disabled"></inputItem>
        <inputItem title="用车事由" v-model="detail.usercause" :disabled="disabled"></inputItem>
        <inputItem title="使用时间" type="datetime" name="usetime" :initialContent="formatDate(detail.usetime,'yyyy-MM-dd hh:mm')" :disabled="disabled" :contentChanged="contentChanged"></inputItem>
        <inputItem title="项目名称" type="others" ref="project_name" :initialContent="detail.project_name" :otherClicked="clickProject" :disabled="disabled"></inputItem>
        <inputItem title="客户名称" type="others" ref="customer_name" :initialContent="detail.customer_name" :otherClicked="clickCustomer" :disabled="disabled"></inputItem>
        <inputItem title="业务代表" type="others" ref="user_name" :initialContent="detail.user_name" :otherClicked="clickUser" :disabled="disabled"></inputItem>
        <inputItem title="出车时间" v-if="detail.usestatus>=4" type="datetime" name="outtime" :initialContent="formatDate(detail.outtime,'yyyy-MM-dd hh:mm')" :disabled="detail.usestatus!=4" :contentChanged="contentChanged"></inputItem>
        <inputItem title="出车里程" v-if="detail.usestatus>=4" name="beforeusemiles" :initialContent="String(detail.beforeusemiles)" :disabled="detail.usestatus!=4" :contentChanged="contentChanged"></inputItem>
        <inputItem title="还车时间" v-if="detail.usestatus>=5" type="datetime" name="returntime" :initialContent="formatDate(detail.returntime,'yyyy-MM-dd hh:mm')" :disabled="detail.usestatus!=5" :contentChanged="contentChanged"></inputItem>
        <inputItem title="还车里程" v-if="detail.usestatus>=5" name="afterusemiles" :initialContent="String(detail.afterusemiles)" :disabled="detail.usestatus!=5" :contentChanged="contentChanged"></inputItem>
        <uploadBox v-model="attachs" v-if="detail.usestatus>=4" :allowed="detail.usestatus==4||detail.usestatus==5"> </uploadBox>
        <div class="mobile-detail-step" v-if="detail && detail.usestatus > 1">
          <Steps :current="flows.step" :status="flows.status" direction="vertical">
            <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName" :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')"></Step>
          </Steps>
        </div>
      </cube-scroll>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
    <optionDrawer ref="optionDrawerProject" v-if="showOptionsProject" :onBgClicked="onBgClickedProject" :onSearch="onSearchProject" :items="projects" @optionClicked="optionClickedProject"> </optionDrawer>
    <optionDrawer ref="optionDrawerCustomer" v-if="showOptionsCustomer" :onBgClicked="onBgClickedCustomer" :onSearch="onSearchCustomer" :items="customers" @optionClicked="optionClickedCustomer"> </optionDrawer>
    <optionDrawer ref="optionDrawerUser" v-if="showOptionsUser" :onBgClicked="onBgClickedUser" :onSearch="onSearchUser" :items="users" @optionClicked="optionClickedUser"> </optionDrawer>
  </div>
</template>

<script>
import { GetCar, GetCarUse, AuditCarUse, SaveCarUse, CancelCarUse, OutCarUse, ReturnCarUse } from "@/api";
import { formatDate, formatMoney } from "@/utils/format.js";
import { loadListAction, loadDetailAction, submitAction, auditAction, cancelAction, loadProjectOptionAction, loadCustomerOptionAction, loadUserOptionAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      flows: [],
      attachs: [],
      buttons: [],
      showOptions: false,
      disabled: false,
      carOption: [],
      customers: [],
      showOptionsCustomer: false,
      projects: [],
      showOptionsProject: false,
      users: [],
      showOptionsUser: false,
    };
  },
  methods: {
    formatDate: formatDate,
    formatMoney: formatMoney,
    contentChanged: function (index, value) {
      switch (index) {
        case "carid":
          this.detail.carid = value;
          break;
        case "usetime":
          this.detail.usetime = value;
          break;
        case "outtime":
          this.detail.outtime = value;
          break;
        case "beforeusemiles":
          this.detail.beforeusemiles = value;
          break;
        case "returntime":
          this.detail.returntime = value;
          break;
        case "afterusemiles":
          this.detail.afterusemiles = value;
          break;
      }
    },
    getCarName: function (pid) {
      var cars = this.carOption.filter(item => { return item.value == pid });
      if (cars.length > 0) {
        return cars[0].text;
      }
      return "";
    },
    onSearchProject: function (value) {
      loadProjectOptionAction(this, value, (data) => { this.projects = data; });
    },
    onBgClickedProject: function () {
      this.showOptionsProject = false;
    },
    optionClickedProject: function (item) {
      this.detail.project_id = item.id;
      this.detail.project_name = item.name;
      this.detail.customer_id = item.customer_id;
      this.detail.customer_name = item.customer_name;
      this.detail.user_id = item.leader_id;
      this.detail.user_name = item.leader_name;
      this.$refs.project_name.setContent(item.name);
      this.$refs.customer_name.setContent(item.customer_name);
      this.$refs.user_name.setContent(item.leader_name);
      this.showOptionsProject = false;
    },
    clickProject: function (index) {
      this.itemIndex = Number(index);
      this.showOptionsProject = true;
      this.$nextTick(() => { setTimeout(() => { this.$refs.optionDrawerProject.show(); }, 100); });
    },
    onSearchCustomer: function (value) {
      loadCustomerOptionAction(this, value, (data) => { this.customers = data; });
    },
    onBgClickedCustomer: function () {
      this.showOptionsCustomer = false;
    },
    optionClickedCustomer: function (item) {
      this.detail.customer_id = item.id;
      this.detail.customer_name = item.name;
      this.detail.user_id = item.user_id;
      this.detail.user_name = item.user_name;
      this.$refs.customer_name.setContent(item.name);
      this.$refs.user_name.setContent(item.user_name);
      this.showOptionsCustomer = false;
    },
    clickCustomer: function (index) {
      this.itemIndex = Number(index);
      this.showOptionsCustomer = true;
      this.$nextTick(() => { setTimeout(() => { this.$refs.optionDrawerCustomer.show(); }, 100); });
    },
    onSearchUser: function (value) {
      loadUserOptionAction(this, value, (data) => { this.users = data; });
    },
    onBgClickedUser: function () {
      this.showOptionsUser = false;
    },
    optionClickedUser: function (item) {
      this.detail.user_id = item.id;
      this.detail.user_name = item.name;
      this.$refs.user_name.setContent(item.name);
      this.showOptionsUser = false;
    },
    clickUser: function (index) {
      this.itemIndex = Number(index);
      this.showOptionsUser = true;
      this.$nextTick(() => { setTimeout(() => { this.$refs.optionDrawerUser.show(); }, 100); });
    },
    buttonClick: function (title) {
      switch (title) {
        case "保存草稿":
          this.saveCarUse(1);
          break;
        case "提交审核":
          this.saveCarUse(2);
          break;
        case "审核通过":
          this.auditCarUse(true, "同意");
          break;
        case "审核拒绝":
          this.auditCarUse(false, "");
          break;
        case "撤回":
          this.cancelCarUse();
          break;
        case "确认出车":
          submitAction(this, OutCarUse, { model: this.detail, attachs: this.attachs }, () => { this.$router.go(-1); }, null, true, "是否确定出车？");
          break;
        case "还车登记":
          console.log(this.detail);
          submitAction(this, ReturnCarUse, { model: this.detail, attachs: this.attachs }, () => { this.$router.go(-1); }, null, true, "是否确定还车？");
          break;
      }
    },
    saveCarUse: function (state) {
      this.detail.usestatus = state;
      submitAction(this, SaveCarUse, { model: this.detail }, () => { this.$router.go(-1); }, () => { this.detail.usestatus = 1; }, (state == 2), "是否确定提交？");
    },
    auditCarUse: function (pass, memo) {
      auditAction(this, AuditCarUse, this.id, pass, memo);
    },
    cancelCarUse: function () {
      cancelAction(this, CancelCarUse, this.id);
    },
    dataRequest: function () {
      loadDetailAction(this, GetCarUse, { id: this.id }, (data) => {
        this.detail = data.list[0].model;
        this.flows = data.list[0].flows;
        this.attachs = data.list[0].attachs;
        this.buttons = data.list[0].buttons;
        this.disabled = !this.buttons || this.buttons.indexOf("保存草稿") < 0;
      });
    },
  },
  mounted() {
    loadListAction(this, GetCar, { state: 1, page: { pageIndex: 1, pageSize: 100, } }, (data) => {
      var that = this;
      data.list.forEach(function (item) {
        that.carOption.push({ text: item.car_id + '（' + item.brand + String(item.seat_num) + '座）', value: item.id });
      });

      if (this.$route.query.id) {
        this.id = this.$route.query.id;
        this.dataRequest();
      }
    });
  },
};
</script>
